import data from "./projectData.js";
import upload from '../../../components/uploadImg.vue';
import uploadFile from '../../../components/uploadUpgrade.vue';
import ElImageViewer from 'element-ui/packages/image/src/image-viewer';
import area from '../../../assets/common/Area.json';
export default {
	name: '',
	data,
	created() {
		this.getProject();
		this.getTableData();
		this.getInvoicingRate();
		this.getCompany();
		this.getRateList();
		area.data.sort(function(item1, item2) {
			return item1.name.localeCompare(item2.name, 'zh-CN');
		})
		this.regionalList = area.data;
	},
	mounted() {},
	computed: {
		tableList: function() {
			let array = [];
			let nameArr = [];
			let regionalArr = [];
			let stateArr = [];
			nameArr = this.tableData.filter((i) => {
				if (!this.searchTxt) {
					return true
				}
				if (i.name.indexOf(this.searchTxt) != -1) {
					return true
				}
				return false
			});

			regionalArr = nameArr.filter((i) => {
				if (this.regional.length == 0) {
					return true
				}
				if (i.area.indexOf(this.regional.toString()) != -1) {
					return true
				}
				return false
			});

			stateArr = regionalArr.filter((i) => {
				if (!this.state) {
					return true
				}
				if (i.status == this.state) {
					return true
				}
				return false
			});

			array = stateArr;
			return array
		},
		projectOptions: function() {
			let arr = this.projectList.filter((i) => {
				// // console.log(i)
				if (!this.companyTxt) {
					return true
				}

				if (i.text.indexOf(this.companyTxt) != -1) {
					return true
				} else {
					let childArr = i.children.filter((v) => {
						if (v.text.indexOf(this.companyTxt) != -1) {
							return true
						}
					})
					return childArr.length > 0 ? true : false;
				}
				return false
			})
			// // console.log(arr)
			return arr
		}
	},
	updated() {
		this.$nextTick(() => {
			this.$refs.table.doLayout()
		})
	},
	methods: {
		// 获取项目下拉框列表
		getProject() {
			this.axios.get('web2/cmn/odnr/gcpt').then(res => {
				this.projectList = [];
				if (res.status) {
					this.projectList = res.data;
				}
			});
		},
		// 获取表格数据
		getTableData() {
			this.axios.get('web2/app/pb/gdor/glist').then(res => {
				this.tableData = [];
				if (res.status) {
					res.data.forEach(i => {
						i.equipmentNum = 1;
						this.tableData.push(i);
					});
				}
			});
		},
		// 获取表格详情数据
		getDetailsData() {
			var data = {
				id: this.detailsId
			};
			this.axios.post('web2/app/pb/gdor/ginfo', data).then(res => {
				this.description = '';
				if (res.status) {
					this.detailsAllInfo = res.data;
					for (let k in res.data.info) {
						if (this.detailsInfo.hasOwnProperty(k)) {
							this.detailsInfo[k] = res.data.info[k];
						} else if (this.invoicingInfoData.hasOwnProperty(k)) {
							this.invoicingInfoData[k] = res.data.info[k];
						}
					}
					this.invoicingInfoData.remarkHtml = this.invoicingInfoData.remark.replace(/\n/g, '<br/>')
					this.contactData = res.data.list;
					this.contractData = [...new Set([...res.data.arr1, ...res.data.arr2])];
					this.detailsDrawer = true;
				}
			});
		},
		// 获取项目日志 web2/app/pb/gdor/gplist id
		getProjectLog() {
			var data = {
				id: this.detailsId
			};
			this.axios.post('web2/app/pb/gdor/gplist', data).then(res => {
				this.projectLogData = [];
				if (res.status) {
					this.projectLogData = res.data;
				}
			});
		},
		// 获取项目日志图片 web2/app/pb/gdor/gpjc id name（pn)
		getProjectImg(value) {
			var val = {
				id: this.detailsId,
				name: value.pn
			};
			this.showViewer = false;
			this.srcList = [];
			this.axios.post('web2/app/pb/gdor/gpjc', val, {
				responseType: 'arraybuffer'
			}).then(res => {
				// // console.log(res);
				this.showViewer = false;
				this.srcList = [];
				const data = res;
				var headersName = res.headers['content-disposition'];

				if (res.headers['content-type'].split(';')[0] != 'application/json') {
					if (headersName != undefined) {
						this.fileName = decodeURIComponent(escape(headersName.split('=')[1]));
					}
					// data 是返回来的二进制数据
					var blob = new Blob([data], {
						type: 'image/png'
					});
					var href = window.URL.createObjectURL(blob);
					this.srcList = [href];
					this.showViewer = true;
				} else {
					// arraybuffer转字符串
					let resBlob = new Blob([res]);
					let reader = new FileReader();
					reader.readAsText(resBlob, 'utf-8');
					reader.onload = () => {
						var type = typeof JSON.parse(reader.result);
						if (type == 'string') {
							this.$message({
								message: reader.result,
								type: 'error'
							});
						} else if (type == 'object') {
							let res = JSON.parse(reader.result);
							this.$message({
								message: res.message,
								type: 'error'
							});
						}
					};
				}
			});
		},
		// 获取财务日志
		getFinancial() {
			var data = {
				id: this.detailsId
			};
			this.axios.post('web2/app/pb/gdor/gflist', data).then(res => {
				this.logData = [];
				if (res.status) {
					this.logData = res.data;
				}
			});
		},
		// 获取外部信息和税率 web2/app/pb/gdor/givlist
		getInvoicingRate() {
			var data = {
				page: this.invoicingPagination.page,
				size: this.invoicingPagination.size,
			}
			this.axios.post('web2/app/pb/gdor/givlist', data).then((res) => {
				this.invoicingData = [];
				this.rateData = [];
				if (res.status) {
					this.invoicingData = res.data.a.list;
					this.rateData = res.data.b;
					this.invoicingTotal = res.data.a.total;
				}
			})
		},
		// 获取详情开票信息公司选择列表 web2/app/pb/gdor/giian
		getCompany() {
			this.axios.get('web2/app/pb/gdor/giian').then((res) => {
				this.companyList = [];
				if (res.status) {
					this.companyList = res.data;
				}
			})
		},
		// 获取详情开票信息税率选择列表 web2/app/pb/gdor/gcit
		getRateList() {
			this.axios.get('web2/app/pb/gdor/gcit').then((res) => {
				this.rateList = [];
				if (res.status) {
					this.rateList = res.data;
				}
			})
		},
		// 表格新增
		addTable() {
			var data = {};
			if (this.form.id.length == 0) {
				this.$message({
					showClose: true,
					message: '请先选择项目！',
					type: 'warning'
				});
				return;
			}
			for (let k in this.form) {
				if (k == 'id') {
					data[k] = this.form[k][1];
				} else if (k == 'area') {
					data[k] = this.form[k].toString();
				} else {
					data[k] = this.form[k];
				}
			}
			this.axios.post('web2/app/pb/uda/add', data).then(res => {
				if (res.status) {
					this.$message({
						showClose: true,
						message: '新增成功',
						type: 'success'
					});
					this.getTableData();
					this.dialogTable = false;
				}
			});
		},
		// 获取财务日志图片 web2/app/pb/gdor/gfpc  id name（pn)
		getImgUrl(value, preview = true, ref = '', dataName = "", index = 0) {
			// value: 图片名称,
			// preview: 是否预览, 
			// ref: 需要回显图片的组件ref名称, 
			// dataName: 需要存储的的数据在data中的变量名(数组)
			// index: 存储数据数组下标
			var val = {
				id: this.detailsId,
				name: value
			};
			this.showViewer = false;
			this.srcList = [];
			this.axios.post('web2/app/pb/gdor/gfpc', val, {
				responseType: 'arraybuffer'
			}).then(res => {
				// // console.log(res);
				this.showViewer = false;
				this.srcList = [];
				const data = res;
				var headersName = res.headers['content-disposition'];

				if (res.headers['content-type'].split(';')[0] != 'application/json') {
					if (headersName != undefined) {
						this.fileName = decodeURIComponent(escape(headersName.split('=')[1]));
					}
					// data 是返回来的二进制数据
					var blob = new Blob([data], {
						type: 'image/png'
					});
					var href = window.URL.createObjectURL(blob);
					if (preview) {
						this.srcList = [href];
						this.showViewer = true;
					} else {
						if (ref != '') {
							this.$refs[ref].setFileData(href);
						}
						this[dataName][index] = href;
					}
				} else {
					// arraybuffer转字符串
					let resBlob = new Blob([res]);
					let reader = new FileReader();
					reader.readAsText(resBlob, 'utf-8');
					reader.onload = () => {
						var type = typeof JSON.parse(reader.result);
						if (type == 'string') {
							this.$message({
								message: reader.result,
								type: 'error'
							});
						} else if (type == 'object') {
							let res = JSON.parse(reader.result);
							this.$message({
								message: res.message,
								type: 'error'
							});
						}
					};
				}
			});
		},
		// 区域选择
		handleChange(value) {},
		// 表格编辑
		tableEditor(row) {},
		// 表格删除
		handleDelete(row) {
			this.$confirm(`是否确定要删除<strong>${row.name}</strong>项目?`, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
				dangerouslyUseHTMLString: true
			}).then(() => {
				var data = {
					id: row.id
				};
				this.axios.post('web2/app/pb/uda/dltp', data).then((res) => {
					if (res.status) {
						this.$message({
							showClose: true,
							message: '删除成功',
							type: 'success'
						});
						this.getTableData();
					}
				})
			}).catch(() => {

			});
		},
		// 外部开票信息添加
		addAllInvoicing() {
			var data = {
				...this.allInvoiceInfo
			};
			Reflect.deleteProperty(data, 'id');
			for (let k in data) {
				if (k != 'phone' && k != 'fax' && k != 'bno') {
					if (data[k] == '') {
						this.$message({
							showClose: true,
							message: '请将必填信息填写完整',
							type: 'warning'
						});
						return
					}
				}
			}
			this.axios.post('web2/app/pb/uda/adii', data).then((res) => {
				if (res.status) {
					this.$message({
						showClose: true,
						message: '开票信息添加成功',
						type: 'success'
					});
					this.getInvoicingRate();
					this.getCompany();
					this.dialogAllInvoiceInfo = false;
				}
			})
		},
		// 外部开票信息编辑
		modifyAllInvoicing() {
			var data = {
				...this.allInvoiceInfo
			};
			for (let k in data) {
				if (k != 'phone' && k != 'fax' && k != 'bno') {
					if (data[k] == '') {
						this.$message({
							showClose: true,
							message: '请将必填信息填写完整',
							type: 'warning'
						});
						return
					}
				}
			}
			this.axios.post('web2/app/pb/uda/mdii', data).then((res) => {
				if (res.status) {
					this.$message({
						showClose: true,
						message: '开票信息修改成功',
						type: 'success'
					});
					this.getInvoicingRate();
					this.getCompany();
					this.dialogAllInvoiceInfo = false;
				}
			})
		},
		// 外部开票信息删除
		deleteAllInvoicing(row) {
			this.$confirm(`是否删除当前${row.name}开票信息`, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				var data = {
					id: row.id
				};
				this.axios.post('web2/app/pb/uda/dtii', data).then((res) => {
					if (res.status) {
						this.$message({
							showClose: true,
							message: '开票信息删除成功',
							type: 'success'
						});
						this.getInvoicingRate();
						this.getCompany();
					}
				})
			}).catch(() => {
				this.$message({
					type: 'info',
					message: '已取消删除'
				});
			});

		},
		// 外部税率添加
		addRate() {
			var data = {
				...this.rateInfo
			};
			Reflect.deleteProperty(data, 'id');
			for (let k in data) {
				if (data[k] == '') {
					this.$message({
						showClose: true,
						message: '请将必填信息填写完整',
						type: 'warning'
					});
					return
				}
			}
			this.axios.post('web2/app/pb/uda/adct', data).then((res) => {
				if (res.status) {
					this.$message({
						showClose: true,
						message: '税率添加成功',
						type: 'success'
					});
					this.getInvoicingRate();
					this.getRateList();
					this.dialogRate = false;
				}
			})
		},
		// 外部税率编辑
		modifyRate() {
			var data = {
				...this.rateInfo
			};
			for (let k in data) {
				if (data[k] == '') {
					this.$message({
						showClose: true,
						message: '请将必填信息填写完整',
						type: 'warning'
					});
					return
				}
			}
			this.axios.post('web2/app/pb/uda/mfct', data).then((res) => {
				if (res.status) {
					this.$message({
						showClose: true,
						message: '税率修改成功',
						type: 'success'
					});
					this.getInvoicingRate();
					this.getRateList();
					this.dialogRate = false;
				}
			})
		},
		// 外部税率删除
		deleteRate(row) {
			this.$confirm(`是否删除当前${row.name}税率信息`, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				var data = {
					id: row.id
				};
				this.axios.post('web2/app/pb/uda/dtct', data).then((res) => {
					if (res.status) {
						this.$message({
							showClose: true,
							message: '删除成功',
							type: 'success'
						});
						this.getInvoicingRate();
						this.getRateList();
					}
				})
			}).catch(() => {
				this.$message({
					type: 'info',
					message: '已取消删除'
				});
			});
		},
		// 打开详情抽屉
		openDrawer(row) {
			this.activeName = '1';
			this.detailsTitle = row.name;
			this.detailsId = row.id;
			for (let k in this.detailsInfo) {
				this.detailsInfo[k] = row[k];
			}
			this.ModifyShow = false;
			this.getDetailsData();
			this.getFinancial();
			this.getProjectLog();
		},
		// 添加/修改联系人 'web2/app/pb/uda/adci'
		addContact() {
			var data = {};
			for (let k in this.contactForm) {
				if (k == 'name' || k == 'phone') {
					if (this.contactForm[k] == '') {
						this.$message({
							showClose: true,
							message: '请将信息填写完整',
							type: 'warning'
						});
					}
				}
				if (this.dialogType != '联系方式') {
					if (k != 'bid' && k != 'cid') {
						data[k] = this.contactForm[k];
					}
				} else {
					if (k != 'id') {
						data[k] = this.contactForm[k];
					}
				}
			}
			if (this.dialogType != '联系方式') {
				this.axios.post('web2/app/pb/uda/adci', data).then(res => {
					if (res.status) {
						this.$message({
							showClose: true,
							message: '新增成功',
							type: 'success'
						});
						this.getDetailsData();
						this.dialogContact = false;
					}
				});
			} else {
				this.axios.post('web2/app/pb/uda/mdci', data).then(res => {
					if (res.status) {
						this.$message({
							showClose: true,
							message: '修改成功',
							type: 'success'
						});
						this.getDetailsData();
						this.dialogContact = false;
					}
				});
			}

		},
		// 删除联系人 
		deleteContact(row) {
			var data = {
				bid: this.detailsId,
				cid: row.id
			};
			this.$confirm(`确定删除<strong>${row.name}</strong>的联系方式`, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
				dangerouslyUseHTMLString: true
			}).then(() => {
				this.axios.post('web2/app/pb/uda/dlci', data).then((res) => {
					if (res.status) {
						this.$message({
							showClose: true,
							message: '删除成功',
							type: 'success'
						});
						this.getDetailsData();
					}
				})
			}).catch(() => {});
		},
		// 修改项目状态 'web2/app/pb/uda/mps'
		modifyState(val) {
			var data = {
				id: this.detailsId,
				type: val
			};
			this.axios.post('web2/app/pb/uda/mps', data).then(res => {
				if (res.status) {
					this.$message({
						showClose: true,
						message: val == 1 ? '项目启动成功' : '项目结束成功',
						type: 'success'
					});
					this.detailsInfo.status = val;
					this.getTableData();
				}
			});
		},
		// 打开详情修改模式
		setModifyDetails() {
			for (let k in this.detailsModify) {
				if (k != 'id' && k != 'area' && k != 'begin' && k != 'end' && k != 'je') {
					this.detailsModify[k] = this.detailsInfo[k];
				} else {
					if (k == 'begin' || k == 'end') {
						if (this.detailsInfo[k] != '-' && this.detailsInfo[k] != '~' && this.detailsInfo[k] != '') {
							this.detailsModify[k] = new Date(this.detailsInfo[k]).format('yyyy-MM-dd');
						} else {
							this.detailsModify[k] = '';
							// if (k == 'begin') {
							// 	this.detailsModify[k] = '-';
							// } else {
							// 	this.detailsModify[k] = '~';
							// }
						}
					}
				}
			}
			this.detailsModify.id = this.detailsId;
			this.detailsModify.area = this.detailsInfo.area.split(',');
			this.detailsModify.je = this.detailsInfo.je1;
			// console.log(this.detailsModify)
			setTimeout(() => {
				this.ModifyShow = true;
			}, 100)
		},
		// 修改详情信息 'web2/app/pb/uda/mpbi'
		modifyDetails() {
			var data = {};
			for (let k in this.detailsModify) {
				if (k == 'area') {
					data[k] = this.detailsModify[k].toString();
				} else {
					data[k] = this.detailsModify[k];
				}
			}
			if (data.begin && data.end) {
				if (new Date(data.begin).getTime() >= new Date(data.end).getTime()) {
					this.$message({
						showClose: true,
						message: '入场时间不能大于或等于离场时间！',
						type: 'warning'
					});
					return
				}
			}

			this.axios.post('web2/app/pb/uda/mpbi', data).then(res => {
				if (res.status) {
					this.$message({
						showClose: true,
						message: '详情信息修改成功',
						type: 'success'
					});
					for (let k in this.detailsInfo) {
						if (k != 'id' && k != 'area' && k != 'begin' && k != 'end' && k != 'je1') {
							if (this.detailsModify[k]) {
								this.detailsInfo[k] = this.detailsModify[k];
							}
						} else {
							if (k == 'begin' || k == 'end') {
								if (this.detailsModify[k] != '-' && this.detailsModify[k] != '~' && this
									.detailsModify[k]) {
									this.detailsInfo[k] = new Date(this.detailsModify[k]).format('yyyy-MM-dd');
								} else {
									if (k == 'begin') {
										this.detailsInfo[k] = '-';
									} else {
										this.detailsInfo[k] = '~';
									}

								}
							} else if (k == 'area') {
								this.detailsInfo[k] = this.detailsModify[k].toString();
							}
						}
					}
					this.detailsInfo.je1 = this.detailsModify.je;
					// console.log(this.detailsInfo)
					this.ModifyShow = false;
					this.getTableData();
				}

			});
		},
		// 修改详情开票信息
		modifyInvoicing() {
			if (this.invoicingInfo.id1 == this.invoicingInfo.id2) {
				this.$message({
					showClose: true,
					message: '委托方(甲方)、受托方(乙方)选择相同，请更改后重试！',
					type: 'warning'
				});
				return
			}
			let data = {
				id: this.detailsId,
				...this.invoicingInfo
			}

			this.axios.post('web2/app/pb/uda/mici', data).then((res) => {
				if (res.status) {
					this.$message({
						showClose: true,
						message: '开票信息修改成功',
						type: 'success'
					});
					this.dialogInvoiceInfo = false;
					this.getDetailsData();
				}
			})
		},
		// 添加项目日志 web2/app/pb/uda/apjl  id  name rmk
		addProjectLog() {
			if (this.projectForm.name == '') {
				this.$message({
					showClose: true,
					message: `标题不能为空`,
					type: 'warning'
				});
				return
			}
			var data = new FormData();
			for (let k in this.projectForm) {
				data.append(k, this.projectForm[k]);
			}
			var files = this.$refs.projectLog.getFile();
			files.forEach(i => {
				data.append('file', i.raw);
			});
			this.axios.post('web2/app/pb/uda/apjl', data).then(res => {
				if (res.status) {
					this.$message({
						showClose: true,
						message: '项目日志添加成功',
						type: 'success'
					});

					this.getProjectLog();
					this.dialogProjectLog = false;
				}
			});
		},
		// 添加财务日志 'web2/app/pb/uda/adfl'
		addFinancial() {
			var data = new FormData();
			var files = [];

			if (this.logType == 2) {
				if (this.InvoicingLogForm.je == '') {
					this.$message({
						showClose: true,
						message: '金额不能为空',
						type: 'warning'
					});
					return
				} else if (!this.InvoicingLogForm.trId) {
					this.$message({
						showClose: true,
						message: '请选择开票类型',
						type: 'warning'
					});
					return
				}
				// 开票信息
				for (let k in this.InvoicingLogForm) {
					if (k != 'num') {
						data.append(k, this.InvoicingLogForm[k]);
					}
				}
			} else {
				if (this.collectionLogForm.je == '') {
					this.$message({
						showClose: true,
						message: '金额不能为空',
						type: 'warning'
					});
					return
				}
				// 收款
				for (let k in this.collectionLogForm) {
					if (k != 'num') {
						data.append(k, this.collectionLogForm[k]);
					}
				}
			}
			data.append('id', this.detailsId);
			files = this.$refs[this.logType == 1 ? 'collectionFile' : 'InvoicingFile'].getFile();
			files.forEach(i => {
				data.append('file', i.raw);
			});
			this.axios.post('web2/app/pb/uda/adfl', data).then(res => {
				if (res.status) {
					this.$message({
						showClose: true,
						message: `${this.logType == 2 ? '开票' : '收款'}日志添加成功`,
						type: 'success'
					});
					if (this.logType == '1') {
						this.getTableData();
						this.getDetailsData()
					}
					this.getFinancial();
					this.dialogLog = false;
				}
			});
		},
		// 财务日志修改
		modifyFinancial() {
			var data = new FormData();
			var files = [];
			data.append('id', this.detailsId);
			if (this.logType == 2) {
				if (!this.InvoicingLogForm.trId) {
					this.$message({
						showClose: true,
						message: '请选择开票类型',
						type: 'warning'
					});
					return
				}
				// 开票信息
				for (let k in this.InvoicingLogForm) {
					if (k != 'je') {
						data.append(k, this.InvoicingLogForm[k]);
					}
				}
			} else {
				// 收款
				for (let k in this.collectionLogForm) {
					if (k != 'je') {
						data.append(k, this.collectionLogForm[k]);
					}
				}
			}
			files = this.$refs[this.logType == 1 ? 'collectionFile' : 'InvoicingFile'].getFile();
			files.forEach(i => {
				data.append('file', i.raw);
			});
			this.axios.post('web2/app/pb/uda/mffl', data).then(res => {
				if (res.status) {
					this.$message({
						showClose: true,
						message: `${this.logType == 2 ? '开票' : '收款'}日志修改成功`,
						type: 'success'
					});
					if (this.logType == '1') {
						this.getTableData();
						this.getDetailsData()
					}
					this.getFinancial();
					this.dialogLog = false;
				}
			});
		},
		// 财务日志删除
		deleteFinancial(row) {
			var data = {
				id: this.detailsId,
				num: row.num
			};
			this.$confirm(`确定删除记录时间为<strong>${row.cdate}</strong>，金额（税率）为<strong>${row.je}（${row.tr}）%</strong>的财务日志`,
				'提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning',
					dangerouslyUseHTMLString: true
				}).then(() => {
				this.axios.post('web2/app/pb/uda/dtfl', data).then((res) => {
					if (res.status) {
						this.$message({
							showClose: true,
							message: '删除成功',
							type: 'success'
						});
						this.getTableData();
						this.getDetailsData()
						this.getFinancial();
					}
				})
			}).catch(() => {});
		},
		// 上传附件（合同，方案） 'web2/app/pb/uda/upld'
		uploadAttachment() {
			var data = new FormData();
			for (let k in this.contractForm) {
				data.append(k, this.contractForm[k])
			}
			var files = this.$refs.contractFile.getFile();
			if (files.length == 0) {
				this.$message({
					showClose: true,
					message: '请先选择文件',
					type: 'warning'
				});
				return
			}
			files.forEach((i) => {
				data.append('files', i.raw);
			})

			this.axios.post('web2/app/pb/uda/upld', data).then(res => {
				if (res.status) {
					this.$message({
						showClose: true,
						message: '文件上传成功',
						type: 'success'
					});
					this.getDetailsData();
					this.dialogContract = false;
				}
			});
		},
		// 下载附件（合同、方案）
		fileDownload(row) {
			var val = {
				id: this.detailsId,
				name: row.name
			};
			this.axios.post('web2/app/pb/gdor/down', val, {
				responseType: 'arraybuffer'
			}).then(res => {
				// // console.log(res);
				const data = res;
				var fileName = '';
				var headersName = res.headers['content-disposition'];

				if (res.headers['content-type'].split(';')[0] != 'application/json') {
					if (headersName != undefined) {
						fileName = decodeURIComponent(escape(headersName.split('=')[1]));
					}
					// data 是返回来的二进制数据
					var blob = new Blob([data], {
						type: 'application/pdf;charset=utf-8'
					});
					var href = window.URL.createObjectURL(blob);
					const link = document.createElement('a');
					link.style.display = 'none';
					link.href = href;
					link.setAttribute('download', fileName);
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
				} else {
					// arraybuffer转字符串
					let resBlob = new Blob([res]);
					let reader = new FileReader();
					reader.readAsText(resBlob, 'utf-8');
					reader.onload = () => {
						var type = typeof JSON.parse(reader.result);
						if (type == 'string') {
							this.$message({
								message: reader.result,
								type: 'error'
							});
						} else if (type == 'object') {
							let res = JSON.parse(reader.result);
							this.$message({
								message: res.message,
								type: 'error'
							});
						}
					};
				}
			});
		},
		// Excel表下载
		downloadTableExcel() {
			// // console.log(this.tableSummary)
			// return
			var data = [
				['序号', '项目名称', '状态', '地域', '委托单位', '项目金额（元）', '应收已付（元）', '应收未付（元）']
			]; //表格标题
			var width = [8, 50, 10, 70, 50, 20, 20, 20]; //表格每列宽度
			var arr = [];
			let ind = 0;
			this.tableList.forEach((i, index) => {
				let je1 = Number(i.je1).toFixed(2);
				let je2 = Number(i.je2).toFixed(2);
				let je3 = Number(i.je3).toFixed(2);
				arr.push({
					id: index + 1,
					index: index + 1,
					name: i.name,
					status: i.status == 1 ? '监测中' : '已结束',
					area: this.formatterArea(i.area),
					ecname: i.ecname,
					je1: this.toThousands(je1),
					je2: this.toThousands(je2),
					je3: this.toThousands(je3),
				})
				ind = index + 1;
			})
			arr.push({
				id: ind + 1,
				index: this.tableSummary[0],
				name: this.tableSummary[1],
				status: this.tableSummary[2],
				area: this.tableSummary[3],
				ecname: this.tableSummary[4],
				je1: this.tableSummary[5],
				je2: this.tableSummary[6],
				je3: this.tableSummary[7],
			})
			data.push(arr);
			// // console.log(arr);
			var name = '项目业务表';
			this.tableDownloadExcel(data, name, width);
		},
		// 右侧图标打开详情抽屉
		iconClick() {
			this.detailsDrawer = true;
			this.ModifyShow = false;
		},
		// 打开弹窗
		openDialog(val) {
			this.dialogName = val;
			if (val == '表格') {
				this.dialogTable = true;
			} else if (val == '全部开票信息') {
				this.dialogInfoTable = true;
			} else if (val == '公司信息') {
				this.dialogAllInvoiceInfo = true;
				this.dialogInvoicingRateName = "新增公司信息";
				this.$nextTick(() => {
					this.$refs.formInvoicing.clearValidate();
				})
			} else if (val == '税率') {
				this.dialogRate = true;
				this.dialogInvoicingRateName = "新增税率";
				this.$nextTick(() => {
					this.$refs.formRate.clearValidate();
				})
			} else if (val == "项目日志") {
				this.projectForm.id = this.detailsId;
				this.dialogProjectLog = true;
			} else if (val == '财务日志') {
				this.logDialogType = '新增';
				this.dialogLog = true;
			} else if (val == '联系方式') {
				this.contactForm.id = this.detailsId;
				this.contactForm.bid = this.detailsId;
				this.dialogContact = true;
			} else if (val == "合同") {
				this.contractForm.id = this.detailsId;
				this.dialogContract = true;
			}
		},
		// 打开修改弹窗
		openModify(val, row) {
			this.dialogName = val;
			this.dialogType = val;
			if (val == '公司信息') {
				this.dialogInvoicingRateName = "编辑公司信息";
				for (let k in this.allInvoiceInfo) {
					this.allInvoiceInfo[k] = row[k];
				}
				this.dialogAllInvoiceInfo = true;
			} else if (val == '税率') {
				this.dialogInvoicingRateName = "编辑税率";
				for (let k in this.rateInfo) {
					this.rateInfo[k] = row[k];
				}
				this.dialogRate = true;
			} else if (val == "开票信息") {
				this.invoicingInfo.id1 = this.detailsAllInfo.info.company1.id;
				this.invoicingInfo.id2 = this.detailsAllInfo.info.company2.id;
				this.invoicingInfo.id3 = this.detailsAllInfo.info.trId;
				this.invoicingInfo.remark = this.detailsAllInfo.info.remark;
				this.dialogInvoiceInfo = true;
			} else if (val == '财务日志') {
				this.logDialogType = '修改';
				this.collectionLogForm.num = row.num;
				this.InvoicingLogForm.num = row.num;
				this.collectionLogForm.je = row.je;
				this.collectionLogForm.rmk = row.rmk1;
				this.InvoicingLogForm.je = row.je;
				this.InvoicingLogForm.rmk = row.rmk2;
				this.InvoicingLogForm.trId = row.trId == 0 ? null : row.trId;
				this.dialogLog = true;
				this.logImg = [];
				this.$nextTick(() => {
					if (row.pn1 != '') {
						this.getImgUrl(row.pn1, false, 'collectionFile', 'logImg', 0);
					}
					if (row.pn2 != '') {
						this.getImgUrl(row.pn2, false, '', 'logImg', 1);
					}
				})
			} else if (val == '联系方式') {
				this.contactForm.id = this.detailsId;
				this.contactForm.bid = this.detailsId;
				this.contactForm.cid = row.id;
				for (let k in this.contactForm) {
					if (k != "id" && k != 'cid' && k != 'bid') {
						this.contactForm[k] = row[k]
					}
				}
				this.dialogContact = true;
			} else if (val == "合同") {
				this.contractForm.id = this.detailsId;
				this.dialogContract = true;
			}
		},
		// 弹窗关闭
		dialogClose(val) {
			// // console.log(val);
			if (val == '表格') {
				for (let k in this.form) {
					if (typeof this.form[k] == 'object') {
						this.form[k] = [];
					} else {
						this.form[k] = '';
					}
				}
			} else if (val == '全部开票信息') {

			} else if (val == '公司信息') {
				for (let k in this.allInvoiceInfo) {
					this.allInvoiceInfo[k] = '';
				}
			} else if (val == '税率') {
				for (let k in this.rateInfo) {
					this.rateInfo[k] = '';
				}
			} else if (val == "开票信息") {
				for (let k in this.invoicingInfo) {
					this.invoicingInfo[k] = '';
				}
			} else if (val == "项目日志") {
				for (let k in this.projectForm) {
					this.projectForm[k] = '';
				}
				this.$refs.projectLog.clearFile();
			} else if (val == '财务日志') {
				for (let k in this.collectionLogForm) {
					if (k != 'type') {
						this.collectionLogForm[k] = '';
					}
				}
				for (let k in this.InvoicingLogForm) {
					if (k != 'type') {
						this.InvoicingLogForm[k] = '';
					}
				}
				this.logImg = [];
				this.logType = '1';
				this.$refs.collectionFile.clearFile();
				this.$refs.InvoicingFile.clearFile();
			} else if (val == '联系方式') {
				for (let k in this.contactForm) {
					this.contactForm[k] = '';
				}
			} else if (val == "合同") {
				this.$refs.contractFile.clearFile();
			}
			this.dialogName = '';
			this.dialogType = '';
		},
		// 外部开票信息列表分页size变化
		handleSizeChange(val) {
			this.invoicingPagination.size = val;
			this.getInvoicingRate();
		},
		// 外部开票信息列表分页page变化
		handleCurrentChange(val) {
			this.invoicingPagination.page = val;
			this.getInvoicingRate();
		},
		// 详情tabs选择
		handleClick(tab, event) {
			// // console.log(tab, event);
		},
		// 详情财务日志添加、编辑日志类型选择
		logTypeClick() {
			if (this.logDialogType == '新增') {
				for (let k in this.collectionLogForm) {
					if (k != 'type') {
						this.collectionLogForm[k] = '';
					}
				}
				for (let k in this.InvoicingLogForm) {
					if (k != 'type') {
						this.InvoicingLogForm[k] = '';
					}
				}
			}

			if (this.logType == 2 && this.logDialogType == '修改') {
				var fileList = this.$refs.InvoicingFile.getFile();
				if (fileList.length == 0) {
					if (this.logImg.length == 2) {
						this.$refs.InvoicingFile.setFileData(this.logImg[1]);
					}
				}
			}
		},
		// 表格新增项目选择框下拉框监听
		visibleChange(val) {
			// // console.log(val)
			var dom = $('#cascader').find('.el-input__inner');
			dom.removeAttr('readonly');
			if (val) {
				// 监听项目名称选择框输入
				dom.on('input', (e) => {
					// // console.log('监听input');
					// // console.log(e)
					var value = $(e.currentTarget).val();
					this.companyTxt = value;
				})
			} else {
				// 监听项目名称选择框输入
				dom.off('input', 'change')
				this.companyTxt = '';
			}
		},
		// 大图预览关闭函数
		closeViewer() {
			this.showViewer = false;
			this.srcList = [];
		},
		// 合同、方案页面图标判断
		setImgSrc(val) {
			var index = val.lastIndexOf('.');
			var type = val.substr(index + 1);
			var url = '';
			if (type == 'jpg' || type == 'png') {
				url = require('../../../assets/images/img.png');
			} else if (type == 'pdf') {
				url = require('../../../assets/images/pdf.png');
			} else if (type == 'ppt') {
				url = require('../../../assets/images/ppt.png');
			} else if (type == 'xls' || type == 'xlsx') {
				url = require('../../../assets/images/xls.png');
			} else if (type == 'docx' || type == 'doc') {
				url = require('../../../assets/images/word.png');
			} else {
				url = require('../../../assets/images/txt.png');
			}
			return url
		},
		// 表格每行添加样式
		tableRowClassName({
			row,
			rowIndex
		}) {
			if (row.status === 0) {
				return 'info-row';
			}
			return '';
		},
		// 格式化表格数字或地域
		formatterTable(row, column) {
			// // console.log(column)
			let txt = ''
			if (column['property'] == 'area') {
				txt = this.formatterArea(row[column['property']]);
			} else {
				let n = Number(row[column['property']]).toFixed(2);
				txt = this.toThousands(n)
			}

			return txt;
		},
		// 格式化地域显示
		formatterArea(val) {
			var reg = new RegExp(',', 'g');
			var test = val.replace(reg, ' / ');
			return test
		},
		// 表格合计
		getSummaries(param) {
			const {
				columns,
				data
			} = param;
			const sums = [];
			columns.forEach((column, index) => {
				if (index === 0) {
					sums[index] = '统计';
					return;
				}
				let k = column.property;
				if (column.property == 'name') {
					k = 'equipmentNum';
				}
				if (column.property == 'status') {
					k = '';
				}
				const values = data.map(item => Number(item[k]));
				if (!values.every(value => isNaN(value))) {
					sums[index] = values.reduce((prev, curr) => {
						// // console.log(prev,curr)
						const value = curr;
						if (!isNaN(value)) {
							var n = prev + curr;
							return n;
						} else {
							return prev;
						}
					}, 0);
					if (k == 'equipmentNum') {
						sums[index] = sums[index] + '个';
					}
				} else {
					sums[index] = '';
				}
			});
			// // console.log(sums)
			var arr = [];
			this.tableSummary = [];
			sums.forEach((i, index) => {
				if (index > 4 && index < 8) {
					let v = Number(i).toFixed(2);
					arr.push(this.toThousands(v));
				} else {
					arr.push(i);
				}
			})
			this.tableSummary = arr;
			return arr;
		}
	},
	components: {
		upload,
		uploadFile,
		ElImageViewer,
	},
	beforeDestroy() {},
	watch: {
		tableList: function(nv) {
			this.maxHeight = $('#equipment-table').height();
		}
	}
};
