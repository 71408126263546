<template>
	<div class="page-box">
		<div class="header">
			<p class="page-title">项目管理</p>
			<div class="search-box">
				<div class="search-cell fuzzy">
					<el-input placeholder="请输入内容" v-model="searchTxt" clearable><!-- <el-button slot="append" icon="el-icon-search" size="mini"></el-button> --></el-input>
				</div>
				<div class="search-cell">
					<el-cascader
						v-model="regional"
						popper-class="cascader-pop-up-box"
						:options="regionalList"
						clearable
						:props="{ expandTrigger: 'click', value: 'name', label: 'name', checkStrictly: true }"
						@change="handleChange"
					></el-cascader>
				</div>
				<div class="search-cell">
					<el-select v-model="state" placeholder="请选择状态" clearable>
						<el-option v-for="(item, index) in stateList" :key="index" :label="item.txt" :value="item.id"></el-option>
					</el-select>
				</div>
				<el-button type="success" size="medium" @click="openDialog('表格')">新增</el-button>
				<el-button type="primary" size="medium" @click="openDialog('全部开票信息')">开票信息</el-button>
			</div>
		</div>
		<div class="table-box scroll-style" id="equipment-table">
			<el-table
				ref="table"
				:data="tableList"
				stripe
				border
				:resizable="false"
				:summary-method="getSummaries"
				:row-class-name="tableRowClassName"
				@row-click="openDrawer"
				show-summary
				:max-height="maxHeight"
			>
				<el-table-column type="index" label="序号" width="50"></el-table-column>
				<el-table-column prop="name" label="项目名称"></el-table-column>
				<el-table-column prop="status" label="状态" width="80" align="center">
					<template slot-scope="scope">
						<el-tag size="small" :type="scope.row.status == 1 ? 'success' : 'info'">{{ scope.row.status == 1 ? '监测中' : '已结束' }}</el-tag>
					</template>
				</el-table-column>
				<el-table-column prop="area" label="地域" :formatter="formatterTable"></el-table-column>
				<el-table-column prop="ecname" label="委托单位"></el-table-column>
				<el-table-column prop="je1" label="项目金额（元）" width="130" :formatter="formatterTable"></el-table-column>
				<el-table-column prop="je2" label="应收已付（元）" width="130" :formatter="formatterTable"></el-table-column>
				<el-table-column prop="je3" label="应收未付（元）" width="130" :formatter="formatterTable"></el-table-column>
				<el-table-column label="" width="80">
					<template slot-scope="scope">
						<el-button size="mini" type="danger" plain icon="el-icon-delete" @click.stop="handleDelete(scope.row)"></el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<div class="bottom-but"><el-button type="primary" plain @click="downloadTableExcel">下载Excel</el-button></div>
		<!-- 外部开票信息 -->
		<el-dialog title="开票信息" :visible.sync="dialogInfoTable" custom-class="infoTable" width="1100px" top="61px" @close="dialogClose('全部开票信息')">
			<div class="info-content">
				<div class="left-box">
					<div class="t-but-box">
						<div class="title">公司信息</div>
						<div class="but"><el-button type="primary" size="mini" @click="openDialog('公司信息')">新增</el-button></div>
					</div>
					<div class="content-box">
						<div class="list-box">
							<vue-scroll v-show="invoicingData.length != 0">
								<div class="infoBox" v-for="(i, index) in invoicingData" :key="index">
									<el-descriptions :column="1">
										<template slot="title">
											<p>公司：{{ i.name }}</p>
										</template>
										<el-descriptions-item label="税号" content-class-name="ein-extra">
											<p class="ein">{{ i.tno }}</p>
											<div class="extra-but" v-show="i.md == 1">
												<el-button type="warning" size="mini" icon="el-icon-edit" @click="openModify('公司信息', i)"></el-button>
												<el-button type="danger" size="mini" icon="el-icon-delete" @click="deleteAllInvoicing(i)"></el-button>
											</div>
										</el-descriptions-item>
										<el-descriptions-item label="公司地址">{{ i.address }}</el-descriptions-item>
									</el-descriptions>
									<el-descriptions :column="2">
										<el-descriptions-item label="联系电话">{{ i.phone }}</el-descriptions-item>
										<el-descriptions-item label="传真">{{ i.fax }}</el-descriptions-item>
									</el-descriptions>
									<el-descriptions :column="3">
										<el-descriptions-item label="开户行">{{ i.bname }}</el-descriptions-item>
										<el-descriptions-item label="开户行号">{{ i.bno }}</el-descriptions-item>
										<el-descriptions-item label="账号">{{ i.bkt }}</el-descriptions-item>
									</el-descriptions>
								</div>
							</vue-scroll>
							<noData v-if="invoicingData.length == 0" />
						</div>
						<el-pagination
							@size-change="handleSizeChange"
							@current-change="handleCurrentChange"
							:current-page="invoicingPagination.page"
							:page-sizes="[10, 30, 50, 100]"
							:page-size="invoicingPagination.size"
							layout="total, sizes, prev, pager, next"
							:total="invoicingTotal"
						></el-pagination>
					</div>
				</div>
				<div class="right-box">
					<div class="t-but-box">
						<div class="title">税率</div>
						<div class="but"><el-button type="primary" size="mini" @click="openDialog('税率')">新增</el-button></div>
					</div>
					<div class="content-box">
						<vue-scroll v-show="rateData.length != 0">
							<div class="descriptions" v-for="(i, index) in rateData" :key="index">
								<el-descriptions border size="medium" :column="2">
									<template slot="title">
										<p class="title text-overflow" :title="i.name">{{ i.name }}</p>
									</template>
									<el-descriptions-item label="税率">{{ i.tr }}%</el-descriptions-item>
									<el-descriptions-item label-class-name="lable-none" content-class-name="extra-but" v-show="i.md == 1">
										<el-button type="warning" size="mini" icon="el-icon-edit" @click="openModify('税率', i)"></el-button>
										<el-button type="danger" size="mini" icon="el-icon-delete" @click="deleteRate(i)"></el-button>
									</el-descriptions-item>
								</el-descriptions>
							</div>
						</vue-scroll>
						<noData v-if="rateData.length == 0" />
					</div>
				</div>
			</div>
		</el-dialog>
		<!-- 添加编辑公司信息（外部） -->
		<el-dialog
			:title="dialogInvoicingRateName"
			:visible.sync="dialogAllInvoiceInfo"
			custom-class="invoicingInfoForm"
			width="870px"
			top="18vh"
			:close-on-click-modal="false"
			@close="dialogClose('公司信息')"
		>
			<el-form ref="formInvoicing" :model="allInvoiceInfo" label-position="left" label-width="75px" :show-message="false">
				<el-form-item label="公司" prop="name" class="company" required><el-input v-model="allInvoiceInfo.name"></el-input></el-form-item>
				<el-form-item label="税号" prop="tno" class="ein" required><el-input v-model="allInvoiceInfo.tno"></el-input></el-form-item>
				<el-form-item label="公司地址" prop="address" class="address" required><el-input v-model="allInvoiceInfo.address"></el-input></el-form-item>
				<div class="form-cell">
					<el-form-item label="联系电话" prop="phone" class="phone"><el-input v-model="allInvoiceInfo.phone"></el-input></el-form-item>
					<el-form-item label="传真" prop="bname" label-width="35px" class="m-l fax"><el-input v-model="allInvoiceInfo.fax"></el-input></el-form-item>
				</div>
				<div class="form-cell">
					<el-form-item label="开户行" prop="bname" class="bank" required><el-input v-model="allInvoiceInfo.bname"></el-input></el-form-item>
					<el-form-item label="开户行号" prop="bno" label-width="62px" class="m-l-r bankNumber"><el-input v-model="allInvoiceInfo.bno"></el-input></el-form-item>
					<el-form-item label="账号" prop="bkt" class="account" label-width="45px" required><el-input v-model="allInvoiceInfo.bkt"></el-input></el-form-item>
				</div>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="dialogAllInvoiceInfo = false">取 消</el-button>
				<el-button v-show="dialogInvoicingRateName == '新增公司信息'" type="primary" @click="addAllInvoicing">确 定</el-button>
				<el-button v-show="dialogInvoicingRateName == '编辑公司信息'" type="primary" @click="modifyAllInvoicing">保 存</el-button>
			</div>
		</el-dialog>
		<!-- 添加税率（外部） -->
		<el-dialog
			:title="dialogInvoicingRateName"
			:visible.sync="dialogRate"
			custom-class="invoicingInfoForm"
			width="500px"
			top="18vh"
			:close-on-click-modal="false"
			@close="dialogClose('税率')"
		>
			<el-form ref="formRate" :model="rateInfo" label-position="left" label-width="50px" :show-message="false">
				<el-form-item label="名称" prop="name" required><el-input v-model="rateInfo.name"></el-input></el-form-item>
				<el-form-item label="税率" prop="tr" class="rate" required>
					<el-input v-model="rateInfo.tr"></el-input>
					%
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="dialogRate = false">取 消</el-button>
				<el-button v-show="dialogInvoicingRateName == '新增税率'" type="primary" @click="addRate">确 定</el-button>
				<el-button v-show="dialogInvoicingRateName == '编辑税率'" type="primary" @click="modifyRate">保 存</el-button>
			</div>
		</el-dialog>
		<!-- 详情 -->
		<el-drawer :visible.sync="detailsDrawer" :with-header="true" size="1050px">
			<div slot="title">
				<span class="drawer-title">{{ detailsTitle }}</span>
				<el-tag size="small" :type="detailsInfo.status == 1 ? 'success' : 'info'">{{ detailsInfo.status == 1 ? '监测中' : '已结束' }}</el-tag>
			</div>
			<div class="detail-content">
				<div class="top-box">
					<el-descriptions title="" :column="1">
						<el-descriptions-item class="line" label="地域">
							<p v-show="!ModifyShow">{{ formatterArea(detailsInfo.area) }}</p>
							<el-cascader
								v-if="ModifyShow"
								v-model="detailsModify.area"
								popper-class="cascader-pop-up-box"
								size="small"
								:options="regionalList"
								:props="{ expandTrigger: 'click', value: 'name', label: 'name', checkStrictly: true }"
							></el-cascader>
						</el-descriptions-item>
						<el-descriptions-item label="委托单位">
							<p v-show="!ModifyShow">{{ detailsInfo.ecname }}</p>
							<el-input v-show="ModifyShow" size="small" v-model="detailsModify.ecname" placeholder="请输入"></el-input>
						</el-descriptions-item>
						<template slot="extra">
							<el-button v-show="!ModifyShow && detailsInfo.status != 1" type="success" size="mini" @click="modifyState(1)">启动项目</el-button>
							<el-button v-show="!ModifyShow && detailsInfo.status == 1" type="danger" size="mini" @click="modifyState(0)">结束项目</el-button>
							<el-button v-show="!ModifyShow" type="primary" size="mini" @click="setModifyDetails">修改</el-button>
							<el-button v-show="ModifyShow" type="primary" size="mini" @click="modifyDetails">保存</el-button>
							<el-button v-show="ModifyShow" type="info" size="mini" @click="ModifyShow = false">取消</el-button>
						</template>
					</el-descriptions>
					<el-descriptions title="" :column="3">
						<el-descriptions-item label="入场时间">
							<p v-show="!ModifyShow">{{ detailsInfo.begin }}</p>
							<el-date-picker
								v-show="ModifyShow"
								v-model="detailsModify.begin"
								size="small"
								type="date"
								format="yyyy-MM-dd"
								value-format="yyyy-MM-dd"
								placeholder="选择入场时间"
							></el-date-picker>
						</el-descriptions-item>
						<el-descriptions-item label="委托期限">
							<p v-show="!ModifyShow">{{ detailsInfo.tl }}</p>
							<el-input v-show="ModifyShow" size="small" v-model="detailsModify.tl" placeholder="请输入"></el-input>
						</el-descriptions-item>
						<el-descriptions-item label="离场时间">
							<p v-show="!ModifyShow">{{ detailsInfo.end }}</p>
							<el-date-picker
								v-show="ModifyShow"
								v-model="detailsModify.end"
								size="small"
								type="date"
								format="yyyy-MM-dd"
								value-format="yyyy-MM-dd"
								placeholder="选择离场时间"
							></el-date-picker>
						</el-descriptions-item>
						<el-descriptions-item label="项目金额">
							<p v-show="!ModifyShow">{{ toThousands(Number(detailsInfo.je1).toFixed(2)) }}</p>
							<el-input v-show="ModifyShow" size="small" v-model="detailsModify.je" placeholder="请输入"></el-input>
						</el-descriptions-item>
						<el-descriptions-item label="应收已付" content-class-name="prepaid">{{ toThousands(Number(detailsInfo.je2).toFixed(2)) }}</el-descriptions-item>
						<el-descriptions-item label="应收未付" content-class-name="unpaid">{{ toThousands(Number(detailsInfo.je3).toFixed(2)) }}</el-descriptions-item>
					</el-descriptions>
				</div>
				<div class="tabs-box">
					<el-tabs v-model="activeName" @tab-click="handleClick">
						<el-tab-pane label="开票信息" name="1">
							<div class="invoicingInfo">
								<div class="but">
									<el-button type="primary" size="mini" icon="el-icon-edit" plain @click="openModify('开票信息', invoicingInfoData)"></el-button>
								</div>

								<vue-scroll>
									<div class="info-box">
										<el-descriptions :column="1">
											<template slot="title">
												<p>委托方（甲方）：{{ invoicingInfoData.company1.cname }}</p>
											</template>
											<el-descriptions-item label="税号">{{ invoicingInfoData.company1.tno }}</el-descriptions-item>
											<el-descriptions-item label="公司地址">{{ invoicingInfoData.company1.address }}</el-descriptions-item>
										</el-descriptions>
										<el-descriptions :column="2">
											<el-descriptions-item label="联系电话">{{ invoicingInfoData.company1.phone }}</el-descriptions-item>
											<el-descriptions-item label="传真">{{ invoicingInfoData.company1.fax }}</el-descriptions-item>
										</el-descriptions>
										<el-descriptions :column="3">
											<el-descriptions-item label="开户行">{{ invoicingInfoData.company1.bname }}</el-descriptions-item>
											<el-descriptions-item label="开户行号">{{ invoicingInfoData.company1.bno }}</el-descriptions-item>
											<el-descriptions-item label="账号">{{ invoicingInfoData.company1.bkt }}</el-descriptions-item>
										</el-descriptions>
									</div>
									<div class="info-box">
										<el-descriptions :column="1">
											<template slot="title">
												<p>受托方（乙方）：{{ invoicingInfoData.company2.cname }}</p>
											</template>
											<el-descriptions-item label="税号">{{ invoicingInfoData.company2.tno }}</el-descriptions-item>
											<el-descriptions-item label="公司地址">{{ invoicingInfoData.company2.address }}</el-descriptions-item>
										</el-descriptions>
										<el-descriptions :column="2">
											<el-descriptions-item label="联系电话">{{ invoicingInfoData.company2.phone }}</el-descriptions-item>
											<el-descriptions-item label="传真">{{ invoicingInfoData.company2.fax }}</el-descriptions-item>
										</el-descriptions>
										<el-descriptions :column="3">
											<el-descriptions-item label="开户行">{{ invoicingInfoData.company2.bname }}</el-descriptions-item>
											<el-descriptions-item label="开户行号">{{ invoicingInfoData.company2.bno }}</el-descriptions-item>
											<el-descriptions-item label="账号">{{ invoicingInfoData.company2.bkt }}</el-descriptions-item>
										</el-descriptions>
									</div>
									<div class="rate">
										<el-descriptions :column="1">
											<el-descriptions-item label="税率">{{ invoicingInfoData.tr }}%</el-descriptions-item>
										</el-descriptions>
									</div>
									<div class="note">
										<el-descriptions :column="1">
											<el-descriptions-item label="备注" label-class-name="note-label">
												<span v-html="invoicingInfoData.remarkHtml"></span>
											</el-descriptions-item>
										</el-descriptions>
									</div>
								</vue-scroll>
							</div>
						</el-tab-pane>
						<el-tab-pane label="项目日志" name="2">
							<div class="projectLog-box">
								<div class="top-but-box"><el-button type="primary" size="mini" icon="el-icon-plus" plain @click="openDialog('项目日志')"></el-button></div>
								<div class="content">
									<vue-scroll>
										<el-steps direction="vertical" :space="80">
											<el-step v-for="(i, index) in projectLogData" :key="index">
												<template slot="title">
													<div class="title">
														<div class="text">
															<p class="name text-overflow">{{ i.name }}</p>
															<p class="time">{{ i.cdate }}</p>
														</div>
														<div class="but-box">
															<el-button
																v-show="i.pn"
																type="primary"
																size="mini"
																icon="el-icon-picture-outline"
																plain
																@click="getProjectImg(i)"
															></el-button>
														</div>
													</div>
												</template>
												<template slot="description">
													<div class="log-description">{{ i.rmk }}</div>
												</template>
											</el-step>
										</el-steps>
									</vue-scroll>
								</div>
							</div>
						</el-tab-pane>
						<el-tab-pane label="财务日志" name="3">
							<div class="financial-box">
								<div class="top-but-box">
									<div class="left-box">
										<div class="title"><div class="text">收款</div></div>
									</div>
									<div class="center-box">
										<div class="title"><div class="text">开票</div></div>
									</div>
									<el-button type="primary" size="mini" icon="el-icon-plus" plain @click="openDialog('财务日志')"></el-button>
								</div>
								<div class="content">
									<vue-scroll>
										<el-steps direction="vertical" :space="120">
											<el-step v-for="(i, index) in logData" :key="index">
												<template slot="title">
													<div class="heard-title">
														<!-- <p class="t-time text-overflow">
															<span class="t">记录时间：</span>
															{{ i.cdate }}
														</p> -->
														<p class="amount text-overflow" v-show="i.date1 != ''">
															<span class="t">金额：</span>
															{{ toThousands(Number(i.je).toFixed(2)) }}
														</p>
														<p class="amount text-overflow" v-show="i.date2 != ''">
															<span class="t">金额（税率）：</span>
															{{ toThousands(Number(i.je).toFixed(2)) }}（{{ i.tr }}%）
														</p>
														<div class="extra-but">
															<el-button type="primary" size="mini" icon="el-icon-edit" plain @click="openModify('财务日志', i)"></el-button>
															<el-button type="danger" icon="el-icon-delete" plain size="mini" @click="deleteFinancial(i)"></el-button>
														</div>
													</div>
													<div class="info-b">
														<div class="left-box">
															<div class="title">
																<div class="text">
																	<!-- <p class="name text-overflow">{{ i.cdate }}</p> -->
																	<p class="description-txt">{{ i.rmk1 }}</p>
																</div>
																<div class="but-box">
																	<el-button
																		v-show="i.pn1"
																		type="primary"
																		size="mini"
																		icon="el-icon-picture-outline"
																		plain
																		@click="getImgUrl(i.pn1)"
																	></el-button>
																</div>
															</div>
														</div>
														<div class="right-box">
															<div class="title">
																<div class="text">
																	<!-- <p class="name text-overflow">{{ i.name2 }}</p> -->
																	<p class="description-txt">{{ i.rmk2 }}</p>
																</div>
																<div class="but-box">
																	<el-button
																		v-show="i.pn2"
																		type="primary"
																		size="mini"
																		icon="el-icon-picture-outline"
																		plain
																		@click="getImgUrl(i.pn2)"
																	></el-button>
																</div>
															</div>
														</div>
													</div>
												</template>
												<template slot="description">
													<div class="log-description">
														<div class="left-b time">{{ i.date1 }}</div>
														<div class="right-b time">{{ i.date2 }}</div>
													</div>
												</template>
											</el-step>
										</el-steps>
									</vue-scroll>
								</div>
							</div>
						</el-tab-pane>
						<el-tab-pane label="联系方式" name="4">
							<div class="contact-box">
								<div class="top-but-box"><el-button type="primary" size="mini" icon="el-icon-plus" plain @click="openDialog('联系方式')"></el-button></div>
								<div class="content">
									<vue-scroll>
										<el-descriptions v-for="(item, index) in contactData" :key="index" :title="item.name" :column="2">
											<template slot="title">
												<span class="contact">{{ item.name }}</span>
												<span class="phone-box">
													电话：
													<span class="phone">{{ item.phone }}</span>
												</span>
											</template>
											<el-descriptions-item label="职位" label-class-name="position-name" content-class-name="position text-overflow">
												{{ item.position }}
											</el-descriptions-item>
											<el-descriptions-item label="地址">{{ item.address }}</el-descriptions-item>
											<el-descriptions-item label="备注">{{ item.rmk }}</el-descriptions-item>
											<template slot="extra">
												<el-button type="primary" size="mini" icon="el-icon-edit" plain @click="openModify('联系方式', item)"></el-button>
												<el-button type="danger" icon="el-icon-delete" plain size="mini" @click="deleteContact(item)"></el-button>
											</template>
										</el-descriptions>
									</vue-scroll>
								</div>
							</div>
						</el-tab-pane>
						<el-tab-pane label="合同方案" name="5">
							<div class="contract">
								<div class="top-but-box"><el-button type="primary" size="mini" icon="el-icon-upload" plain @click="openDialog('合同')"></el-button></div>
								<div class="content">
									<vue-scroll>
										<div class="cell" v-for="(i, index) in contractData" :key="index">
											<div class="left">
												<img :src="setImgSrc(i.name)" />
												<p class="name text-overflow">{{ i.name }}</p>
											</div>
											<div class="time">{{ i.time }}</div>
											<div class="right"><el-button size="mini" type="primary" plain icon="el-icon-download" @click="fileDownload(i)"></el-button></div>
										</div>
									</vue-scroll>
								</div>
							</div>
						</el-tab-pane>
					</el-tabs>
				</div>
			</div>
		</el-drawer>
		<!-- 表格新增修改 -->
		<el-dialog title="新增" :visible.sync="dialogTable" custom-class="tableForm" width="500px" :close-on-click-modal="false" @close="dialogClose('表格')">
			<el-form :model="form" label-position="right" label-width="80px">
				<el-form-item id="cascader" class="cascader" label="项目名称" required>
					<el-cascader
						v-model="form.id"
						placeholder="输入公司名或项目名搜索"
						popper-class="cascader-pop-up-box project-cascader"
						:options="projectOptions"
						:props="{ expandTrigger: 'click', value: 'id', label: 'text' }"
						clearable
						@visible-change="visibleChange"
						:show-all-levels="false"
					></el-cascader>
				</el-form-item>
				<el-form-item label="地域" required>
					<el-cascader
						v-model="form.area"
						popper-class="cascader-pop-up-box"
						:options="regionalList"
						:props="{ expandTrigger: 'click', value: 'name', label: 'name', checkStrictly: true }"
					></el-cascader>
				</el-form-item>
				<el-form-item label="委托单位" required><el-input v-model="form.ecname" autocomplete="off"></el-input></el-form-item>
				<el-form-item label="项目金额" required><el-input type="number" v-model="form.je" autocomplete="off"></el-input></el-form-item>
				<el-form-item label="期限" required><el-input v-model="form.tl" autocomplete="off"></el-input></el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="dialogTable = false">取 消</el-button>
				<el-button type="primary" @click="addTable">确 定</el-button>
			</div>
		</el-dialog>
		<!-- 选择开票信息（详情内部） -->
		<el-dialog
			title="选择开票信息"
			:visible.sync="dialogInvoiceInfo"
			custom-class="invoicingInfoForm"
			width="870px"
			top="9vh"
			:close-on-click-modal="false"
			@close="dialogClose('开票信息')"
		>
			<el-form :model="invoicingInfo" label-position="left" label-width="88px">
				<el-form-item label="委托方(甲方)" class="company">
					<el-select v-model="invoicingInfo.id1" clearable placeholder="请选择公司">
						<el-option v-for="item in companyList" :key="item.id" :label="item.name" :value="item.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="受托方(乙方)" class="company">
					<el-select v-model="invoicingInfo.id2" clearable placeholder="请选择公司">
						<el-option v-for="item in companyList" :key="item.id" :label="item.name" :value="item.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="税率" class="rate">
					<el-select v-model="invoicingInfo.id3" clearable placeholder="请选择税率">
						<el-option v-for="item in rateList" :key="item.id" :label="item.msg" :value="item.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="备注" class="note">
					<el-input type="textarea" :rows="3" v-model="invoicingInfo.remark" maxlength="200" show-word-limit></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="dialogInvoiceInfo = false">取 消</el-button>
				<el-button type="primary" @click="modifyInvoicing">确 定</el-button>
			</div>
		</el-dialog>
		<!-- 项目日志新增 -->
		<el-dialog title="新增项目日志" :visible.sync="dialogProjectLog" custom-class="projectLogForm" width="500px" :close-on-click-modal="false" @close="dialogClose('项目日志')">
			<el-form :model="projectForm" label-position="left" label-width="60px">
				<el-form-item label="标题" required><el-input maxlength="20" show-word-limit v-model="projectForm.name" autocomplete="off"></el-input></el-form-item>
				<el-form-item label="备注">
					<el-input type="textarea" :rows="3" maxlength="50" show-word-limit v-model="projectForm.rmk" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="附件"><upload ref="projectLog" :configuration="projectLogAttachment"></upload></el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="dialogProjectLog = false">取 消</el-button>
				<el-button type="primary" @click="addProjectLog">确 定</el-button>
			</div>
		</el-dialog>
		<!-- 财务日志新增 -->
		<el-dialog
			:title="logDialogType + '财务日志'"
			:visible.sync="dialogLog"
			custom-class="logForm"
			width="500px"
			:close-on-click-modal="false"
			@close="dialogClose('财务日志')"
		>
			<div class="log-form-box">
				<el-form :model="collectionLogForm" label-position="right" label-width="78px">
					<el-form-item label="日志类型">
						<el-select v-model="logType" placeholder="请选择日志类型" @change="logTypeClick">
							<el-option label="收款" value="1"></el-option>
							<el-option label="开票" value="2"></el-option>
						</el-select>
					</el-form-item>
				</el-form>
				<el-form :model="collectionLogForm" label-position="right" label-width="78px" v-show="logType == '1'">
					<el-form-item label="金额" required>
						<el-input type="number" v-model="collectionLogForm.je" :readonly="logDialogType == '修改'" step="0.01" autocomplete="off"></el-input>
					</el-form-item>
					<el-form-item label="备注">
						<el-input type="textarea" :rows="3" maxlength="50" show-word-limit v-model="collectionLogForm.rmk" autocomplete="off"></el-input>
					</el-form-item>
					<el-form-item label="附件"><upload ref="collectionFile" :configuration="collectionLog"></upload></el-form-item>
				</el-form>
				<el-form :model="InvoicingLogForm" label-position="right" label-width="78px" v-show="logType == '2'">
					<el-form-item label="金额" required>
						<el-input type="number" v-model="InvoicingLogForm.je" :readonly="logDialogType == '修改'" step="0.01" autocomplete="off"></el-input>
					</el-form-item>
					<el-form-item label="开票类型" required>
						<el-select v-model="InvoicingLogForm.trId" placeholder="请选择开票类型">
							<el-option v-for="item in rateList" :key="item.id" :label="item.msg" :value="item.id"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="备注">
						<el-input type="textarea" :rows="3" maxlength="50" show-word-limit v-model="InvoicingLogForm.rmk" autocomplete="off"></el-input>
					</el-form-item>
					<el-form-item label="附件"><upload ref="InvoicingFile" :configuration="InvoicingLog"></upload></el-form-item>
				</el-form>
			</div>

			<div slot="footer" class="dialog-footer">
				<el-button @click="dialogLog = false">取 消</el-button>
				<el-button v-show="logDialogType == '新增'" type="primary" @click="addFinancial">确 定</el-button>
				<el-button v-show="logDialogType == '修改'" type="primary" @click="modifyFinancial">保 存</el-button>
			</div>
		</el-dialog>
		<!-- 联系方式新增 -->
		<el-dialog
			:title="this.dialogType == '联系方式' ? '修改联系人' : '新增联系人'"
			:visible.sync="dialogContact"
			custom-class="contactForm"
			width="500px"
			:close-on-click-modal="false"
			@close="dialogClose('联系方式')"
		>
			<el-form :model="contactForm" label-position="right" label-width="80px">
				<el-form-item label="姓名" required><el-input v-model="contactForm.name" autocomplete="off"></el-input></el-form-item>
				<el-form-item label="联系电话" required><el-input type="number" v-model="contactForm.phone" autocomplete="off"></el-input></el-form-item>
				<el-form-item label="职位"><el-input v-model="contactForm.position" autocomplete="off"></el-input></el-form-item>

				<el-form-item label="地址">
					<el-input type="textarea" :rows="3" maxlength="50" show-word-limit v-model="contactForm.address" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="备注">
					<el-input type="textarea" :rows="3" maxlength="50" show-word-limit v-model="contactForm.rmk" autocomplete="off"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="dialogContact = false">取 消</el-button>
				<el-button type="primary" @click="addContact">确 定</el-button>
			</div>
		</el-dialog>
		<!-- 添加合同 -->
		<el-dialog title="添加合同方案" :visible.sync="dialogContract" custom-class="contractForm" width="500px" :close-on-click-modal="false" @close="dialogClose('合同')">
			<el-form :model="contractForm" label-position="right" label-width="80px">
				<!-- <el-form-item label="文件类型" required>
					 <el-select v-model="contractForm.type" placeholder="请选择">
					    <el-option
					      v-for="item in fileTypeLIist"
					      :key="item.id"
					      :label="item.name"
					      :value="item.id">
					    </el-option>
					  </el-select>
				</el-form-item> -->
				<el-form-item label="附件" required><upload-file ref="contractFile" :configuration="contractParameter" :txtShow="false"></upload-file></el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="dialogContract = false">取 消</el-button>
				<el-button type="primary" @click="uploadAttachment">确 定</el-button>
			</div>
		</el-dialog>
		<!-- 弹窗打开后关闭显示图标 -->
		<div class="open-icon" v-show="detailsId != '' && detailsId"><span class="el-icon-s-fold" @click="iconClick"></span></div>
		<!-- 大图预览 -->
		<el-image-viewer v-if="showViewer" :on-close="closeViewer" :url-list="srcList" />
	</div>
</template>

<script>
import project from './project.js';
export default project;
</script>

<style scoped lang="scss">
@import './project.scss';
</style>

<style lang="scss">
// 表格新增项目选择弹出框样式修改
.project-cascader {
	.el-cascader-panel {
		display: flex !important;
	}
}
</style>
