export default () => {
	return {
		searchTxt: '', //模糊搜索
		regional: [], //选择的地域
		regionalList: [], //地域列表
		state: null, // 选择的状态
		stateList: [{
				id: 1,
				txt: '完成'
			},
			{
				id: 2,
				txt: '未完成'
			}
		], // 状态列表
		tableData: [], //表格数据
		maxHeight: null, //表格最大高度
		tableSummary: [], // 表格合计数据
		projectList: [], //项目选择列表数据
		companyTxt: '', // 项目选择列表模糊搜索公司名称字符
		detailsDrawer: false, // 详情抽屉是否打开
		detailsTitle: '', // 详情抽屉名称
		detailsId: null, // 详情数据id
		activeName: '1', //详情菜单选择
		dialogName: '', //打开详情弹窗的名字
		dialogType: '', //编辑弹窗打开的名称
		dialogTable: false, //表格新增、修改弹窗是否打开
		detailsInfo: {
			// name: '',
			status: '',
			area: '',
			ecname: '',
			je1: '',
			je2: '',
			je3: '',
			begin: '',
			end: '',
			tl: ''
		}, //详情数据顶部列表数据
		detailsAllInfo: {},//详情抽屉全部数据
		contactData: [], // 联系方式数据
		description: '', // 项目概述
		contractData: [], // 合同数据
		planData: [], // 方案数据
		form: {
			id: [], //项目id
			area: [], //地域
			ecname: '', // 委托单位
			je: '', // 经验
			tl: '' //期限
		}, //表格新增
		invoicingData: [], // 外部开票信息数据
		invoicingPagination: {
			page: 1,
			size: 10,
		}, // 外部开票信息数据分页
		invoicingTotal: 0, // 外部开票信息数据总数
		rateData: [], // 外部税率数据
		dialogInfoTable: false, // 外部开票信息、税率展示弹窗是否显示
		dialogInvoicingRateName: '',// 外部开票信息、税率编辑、新增弹窗名字
		dialogAllInvoiceInfo: false, // 外部开票信息添加、编辑
		allInvoiceInfo: {
			name: '', //公司
			tno: '', //税号
			address: '', //公司地址
			phone: '', //联系电话
			fax: '', //传真
			bname: '', //开户行
			bno: '', //开户行号
			bkt: '', //账号
			id: ''
		}, // 外部开票信息添加、编辑数据
		dialogRate: false, // 外部税率添加、编辑
		rateInfo: {
			name: '', //公司
			tr: '', //税率
			id: ''
		}, // 外部税率添加、编辑数据
		detailsModify: {
			id: '',
			area: [],
			ecname: '',
			begin: '',
			tl: '',
			end: '',
			je: 0,
		}, // 详情修改数据
		ModifyShow: false, // 是否修改详细数据
		invoicingInfoData: {
			company1:{
				address: '',
				bkt: '',
				bname: '',
				bno: '',
				cname: '',
				fax: '',
				phone: '',
				tno: '',
				id: '',
			},
			company2:{
				address: '',
				bkt: '',
				bname: '',
				bno: '',
				cname: '',
				fax: '',
				phone: '',
				tno: '',
				id: '',
			},
			tr: '',
			remark: '',
			remarkHtml: '',
		}, // 开票信息数据
		dialogInvoiceInfo: false, // 开票信息编辑弹窗
		companyList: [], // 公司选择列表
		rateList: [], // 税率选择列表（财务日志开票类型选择列表）
		invoicingInfo: {
			id1: '', // 委托方(甲方)
			id2: '', // 受托方(乙方)
			id3: '', // 税率
			remark: '', //备注
		}, // 开票信息编辑数据(税率,备注)
		projectLogData: [], // 项目日志数据
		dialogProjectLog: false, // 项目日志添加弹窗是否打开
		projectForm: {
			id: '',
			name: '',
			rmk: ''
		}, // 项目日志新增数据
		projectLogAttachment: {
			limit: 1, //最大文件个数
			name: 'projectLog' //上传文件组件的样式名字(多个组件同时存在时，该参数不能重复)
		}, // 项目日志附件上传组件参数
		logData: [], //财务日志数据
		logType: '1', // 财务日志添加类型
		dialogLog: false, //财务日志添加弹窗
		logDialogType: '新增', //财务日志弹窗类型
		logImg: [],//财务日志附件图片数组
		collectionLogForm: {
			num: '',
			type: '1',
			je: '',
			rmk: '',
		}, //财务日志收款新增
		InvoicingLogForm: {
			num: '',
			type: '2',
			je: '',
			rmk: '',
			trId: '',//开票类型
		}, //财务日志开票新增
		invoiceType: [], //发票类型
		collectionLog: {
			limit: 1, //最大文件个数
			name: 'collectionLogImg' //上传文件组件的样式名字(多个组件同时存在时，该参数不能重复)
		}, // 财务日志收款附件上传组件参数
		InvoicingLog: {
			limit: 1, //最大文件个数
			name: 'InvoicingLogImg' //上传文件组件的样式名字(多个组件同时存在时，该参数不能重复)
		}, // 财务日志开票附件上传组件参数
		dialogContact: false, // 联系方式新增弹窗
		contactForm: {
			id: '',
			name: '',
			phone: '',
			rmk: '',
			bid: '',
			cid: '',
			address: '',//地址
			position: '',//职位
		}, // 联系人新增
		dialogContract: false, // 合同新增弹窗
		contractForm: {
			id: '',
			type: 1,
		}, // 合同新增
		fileTypeLIist: [{
				id: 1,
				name: '合同'
			},
			{
				id: 2,
				name: '方案'
			}
		], // 上传合同或方案时类型选择
		contractParameter: {
			limit: 10, //最大文件个数
			name: 'contract', //上传文件组件的样式名字(多个组件同时存在时，该参数不能重复)
			butText: '选择文件',
			type: '.pdf'
		}, // 方案附件上传组件参数
		dialogPlan: false, // 方案新增弹窗
		showViewer: false, // 大图预览是否显示
		srcList: [], // 大图预览图片数据
	};
}
